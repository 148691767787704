/**
 * ! Read this before making _any_ changes to this file.
 * This file contains registration definitions for all
 * standalone components that can be lazy-loaded.
 * _Do not_ register components here that are defined on a module.
 * _Do not_ remove components from here if you don't recognise them.
 * _Do not_ comment components out if they are failing and commit this
 *    file, unless you are completely sure that is OK.
 * _Do_ use `<ng-template lazyLoad="Gantt"></ng-template>` to lazy-load a component
 * _Do_ use PascalCase or camelCase in names, loading is case-insensitive.
 *
 * When creating a component that should be lazy-loaded, set `standalone: true` in the
 *     `@component({standalone: true})` decorator.
 */

import { ComponentRegistration } from 'src/app/components/lazy-loader/types';

// This must match with the menu.json otherwise you'll see 404s.
export const RegisteredComponents: ComponentRegistration[] = [
    // Admin
    { id: 'TenantPreferences', load: () => import('src/app/pages/eos/tenant-preferences/tenant-preferences.component') },
    { id: 'dtToken', load: () => import('src/app/pages/eos/tenant-preferences/dt-token-scope/dt-token-scope.component') },
    { id: 'Health', load: () => import('./pages/admin/health/health.component') },
    { id: 'TenantManagement', load: () => import('src/app/pages/admin/tenant-management/tenant-management.component') },
    { id: 'EmployeeRoles', load: () => import('src/app/pages/admin/employee-roles/employee-roles.component') },
    { id: 'ERIC', load: () => import('src/app/pages/admin/ERIC/ERIC.component') },
    { id: 'PagesTable', load: () => import('src/app/pages/admin/pages-table/pages-table.component') },
    { id: 'AdminTools', load: () => import('src/app/pages/admin/tools/tools.component') },
    { id: 'UserAudit', load: () => import('src/app/pages/admin/user-auditing/user-auditing.component') },

    // General
    { id: 'NoteEditor', load: () => import('src/app/pages/@dialogs/note-editor/note-editor.component') },
    { id: 'FileStorage', load: () => import('src/app/pages/general/file-storage/file-storage.component') },
    { id: 'Files', load: () => import('src/app/pages/general/file-storage/file-storage.component') },
    { id: 'FilesDialog', load: () => import('src/app/pages/general/file-storage/filestorage-dialog/filestorage-dialog.component') },
    { id: 'ApiTokens', load: () => import('src/app/pages/general/api-tokens/api-tokens.component') },
    { id: 'envToken', load: () => import('src/app/pages/general/api-tokens/env-token-scope/env-token-scope.component') },
    // { id: 'Landing', load: () => import('src/app/pages/general/landing/landing.component') },
    { id: 'Login', load: () => import('src/app/pages/general/login/login.component') },
    { id: 'Enrollment', load: () => import('src/app/pages/general/login/enrollment/enrollment.component') },
    { id: 'Frame', load: () => import('src/app/pages/@framework/frame/frame.component') },
    { id: 'PowerBI', load: () => import('src/app/pages/@framework/powerbi/powerbi.component') },
    { id: 'Terms', load: () => import('src/app/pages/general/terms-and-conditions/terms-and-conditions.component') },
    { id: 'ReleaseNotes', load: () => import('src/app/pages/general/release-notes/release-notes.component') },
    { id: 'AssetVisualizer', load: () => import('src/app/pages/general/asset-visualizer/asset-visualizer.component') },
    { id: 'visualizer', load: () => import('src/app/pages/general/asset-visualizer/asset-visualizer.component') },
    { id: 'Subscriptions', load: () => import('src/app/pages/general/elevate-subscriptions/elevate-subscriptions.component') },

    // Reports
    { id: 'ElevateDaily', load: () => import('src/app/pages/eos/elevate-daily/elevate-daily.component')},
    { id: 'Landing', load: () => import('src/app/pages/eos/elevate-daily/elevate-daily.component')},
    { id: 'Home', load: () => import('src/app/pages/eos/elevate-daily/elevate-daily.component')},

    { id: 'elevate-daily-post-details', load: () => import('src/app/pages/eos/elevate-daily/post-details/post-details.component')},
    { id: 'ServiceReport', title: "Service Report", load: () => import('src/app/pages/@reports/service-report/service-report.component')},
    { id: 'SupportReport', title: "Support Report", load: () => import('src/app/pages/@reports/support-report/support-report.component')},
    { id: 'LicenseReport', title: "License Report", load: () => import('src/app/pages/@reports/license-report/license-report.component') },
    //{ id: 'DpsReport', title: "License Report - DPS", load: () => import('src/app/pages/@reports/dps-license-report/dps-license-report.component') },
    { id: 'ProjectTracking', load: () => import('src/app/pages/@reports/project-tracking/project-tracking.component')},
    { id: 'Problem-Analysis', load: () => import('src/app/pages/@reports/problem-analysis-report/problem-analysis-report.component') },
    { id: 'ProblemsByAsset', load: () => import('src/app/pages/@reports/problems-byasset-report/problems-byasset-report.component') },
    { id: 'Dynatrace-UI-Usage', load: () => import('src/app/pages/@reports/dynatrace-ui-usage/dynatrace-ui-usage.component') },
    { id: 'DTU-Usage', load: () => import('src/app/pages/@reports/dtu-usage/dtu-usage.component') },
    { id: 'Elevate-Feedback', load: () => import('src/app/pages/@reports/elevate-feedback/elevate-feedback.component') },
    { id: 'Weekly-Planner', load: () => import('src/app/pages/@reports/weekly-planner/weekly-planner.component') },
    { id: 'Weekly-Generator', load: () => import('src/app/pages/@reports/weekly-planner/weekly-generator/weekly-generator.component') },
    { id: 'Report-Template', load: () => import('src/app/pages/@reports/report-template/report-template.component') },

    // EOB
    { id: 'DTEntity-Claims', load: () => import('src/app/pages/eob/dtentity-claims/dtentity-claims.component') },
    { id: 'Observability-Allocations', load: () => import('src/app/pages/eob/observability-allocations/observability-allocations.component') },
    { id: 'Observability-Associations', load: () => import('src/app/pages/eob/observability-associations/observability-associations.component') },
    { id: 'Observability-Claims', load: () => import('src/app/pages/eob/observability-claims/observability-claims.component') },
    { id: 'Claims-Envselect', load: () => import('src/app/pages/eob/claims-envselect/claims-envselect.component') },
    { id: 'Allocation-Entry', load: () => import('src/app/pages/eob/allocation-entry/allocation-entry.component') },
    { id: 'Coverage-Assign-Allocation', load: () => import('src/app/pages/eob/coverage-assign-allocation/coverage-assign-allocation.component') },
    { id: 'Info', load: () => import('src/app/pages/eob/enterprise-guidelines/enterprise-guidelines.component') },
    // { id: 'Info', load: () => import('src/app/pages/general/asset-navigator/components/info-component/info-component.component') },
    { id: 'environment-auto-import', load: () => import('./pages/eob/environment-auto-import/environment-auto-import.component') },

    // EOM
    { id: 'UserPreferences', load: () => import('src/app/pages/eom/user-preferences/user-preferences.component') },
    { id: 'UserInvite', load: () => import('src/app/pages/@dialogs/user-invite/user-invite.component') },
    { id: 'RegistrationWizard', load: () => import('src/app/components/wizard-user-registration/wizard-user-registration.component') },
    { id: 'PersonaWizard', load: () => import('src/app/pages/general/persona-wizard/persona-wizard.component') },

    // EOS
    { id: 'MetadataMapping', load: () => import('src/app/pages/eos/synchronization-results/metadata-mapping/metadata-mapping.component') },
    { id: 'asset-import', load: () => import('./pages/eos/synchronization-results/synchronization-results.component') },
  //  { id: 'AssetimportWizard', load: () => import('src/app/pages/eos/synchronization-results/asset-import-options/asset-import-options.component') },
    { id: 'EnterpriseCoverage', load: () => import('src/app/pages/eos/enterprise-coverage/enterprise-coverage.component') },
    { id: 'Accounts', load: () => import('src/app/pages/eos/es-accounts/es-accounts.component') },
    { id: 'Observability-Inventory', load: () => import('./pages/eos/observable-inventory/observable-inventory.component') },
    { id: 'Flexpoint', load: () => import('src/app/pages/eos/flexpoint-marketplace/flexpoint-marketplace.component') },
    { id: 'Rate-Cards', load: () => import('src/app/pages/eos/rate-cards/rate-cards.component') },
    { id: 'ccc', load: () => import('src/app/pages/eos/coverage-command-center/coverage-command-center.component') },
    { id: 'Services-Offering', load: () => import('src/app/pages/eos/flexpoint-marketplace/services-offering/services-offering.component') },

    // PMO
    { id: 'Gantt', load: () => import('src/app/pages/pmo/gantt/gantt.component') },
    { id: 'Calendar', load: () => import('src/app/pages/pmo/calendar/calendar.component') },
    { id: 'Calendar-grid', load: () => import('src/app/pages/pmo/calendar/calendar-grid/calendar-grid.component') },
    { id: 'Kanban', load: () => import('src/app/pages/pmo/kanban/kanban.component') },
    { id: 'Timecards', load: () => import('src/app/pages/@dialogs/event-properties/timecards/timecards.component') },
    { id: 'Raci', load: () => import('src/app/pages/pmo/raci/raci.component') },
    { id: 'Events', load: () => import('src/app/pages/pmo/event-inspector/event-inspector.component') },
    { id: 'Event-Hierarchy', load: () => import('src/app/pages/pmo/event-hierarchy/event-hierarchy.component') },
    { id: 'PMO-Quicksave', load: () => import('src/app/pages/pmo/quicksave-dialog/quicksave-dialog.component') },
    { id: 'documentation-generator', load: () => import('src/app/pages/pmo/documentation-generator/documentation-generator.component') },
    { id: 'DocCreateOptions', load: () => import('src/app/pages/pmo/documentation-generator/doc-create-options/doc-create-options.component') },

    // ... These need to be cleaned up!
    { id: 'tasklist', load: () => import('src/app/pages/pmo/active-tasks/active-tasks.component') },
    { id: 'risks', load: () => import('src/app/pages/pmo/active-tasks/active-tasks.component') },
    { id: 'issues', load: () => import('src/app/pages/pmo/active-tasks/active-tasks.component') },
    { id: 'tasks', load: () => import('src/app/pages/pmo/active-tasks/active-tasks.component') },
    { id: 'activetasks', load: () => import('src/app/pages/pmo/active-tasks/active-tasks.component') },

    // Asset Navigator Sub-components
    { id: 'dtorefs', load: () => import('src/app/pages/general/asset-navigator/components/dtoref-table/dtoref-table.component') },
    { id: 'children', load: () => import('src/app/pages/general/asset-navigator/components/children-table/children-table.component') },
    { id: 'assignments', load: () => import('src/app/pages/@dialogs/event-properties/assignments/assignments.component') },
    { id: 'associations', load: () => import('src/app/pages/@dialogs/event-properties/associations/associations.component') },
    { id: 'iframe', load: () => import('src/app/pages/@framework/frame/frame.component') },
    { id: 'memberships', load: () => import('src/app/pages/@dialogs/event-properties/memberships/memberships.component') },

    // Legacy
    { id: 'Questionnaire', load: () => import('src/app/pages/general/questionnaire/questionnaire.component') },

    // Navbar entries
    { id: 'Nav-ActionCenter', load: () => import('src/app/pages/@dialogs/nav-action-center/action-center.component'), mode: 'dark' },
    { id: 'Nav-Feedback', load: () => import('src/app/pages/@dialogs/nav-feedback/feedback.component'), mode: 'dark' },


    { id: 'navigator', load: () => import('src/app/pages/general/asset-navigator/asset-navigator.component') },

    {
        group: "asset-editor",
        matcher: [
            "Project_v1",
            "CoverageGoal_v1",
            "Goal_v1",
            "Objective_v1",
            "CoverageObjective_v1",
            "Task_v1",
            "Event_v1",
            "Risk_v1",
            "Issue_v1",
            "Meeting_v1"
        ],
        load: () => import('src/app/pages/@dialogs/event-properties/event-properties.component')
    },
    {
        group: "asset-editor",
        matcher: [
            "User_v1"
        ],
        load: () => import('src/app/pages/@dialogs/user-properties/user-properties.component')
    },
    {
        group: "asset-editor",
        matcher: [
            "Provider_v1", "Region_v1", "Location_v1", "Networkzone_v1"
        ],
        load: () => import('src/app/pages/@dialogs/topology-properties/topology-properties.component')
    },
    {
        group: "asset-editor",
        matcher: [
            "ObservableInventory_v1"
        ],
        load: () => import('src/app/pages/@dialogs/inventory-properties/inventory-properties.component')
    },
    {
        group: "asset-editor",
        matcher: /.*/,
        load: () => import('src/app/pages/@dialogs/generic-properties/generic-properties.component')
    },
    {
        matcher: /power-import-wizard-(?<mode>[a-zA-Z]+)/,
        load: () => import('src/app/pages/general/import-export/power-import/power-import.component')
    },
    {
        matcher: /power-export-wizard-(?<mode>[a-zA-Z]+)/,
        load: () => import('src/app/pages/general/import-export/power-export/power-export.component')
    },

    /**
     * TODO: only reference `asset-dashboard`
     */
    { id: 'asset-dashboard', load: () => import('src/app/pages/general/dashboard/dashboard.component') },
    { id: 'dashboard', load: () => import('src/app/pages/general/dashboard/dashboard.component') },

    // APITools loader
    { matcher: [
        "DtApiConfigManager",
        "DtHostMonitoringMode",
        "DtBatchHostConfig",
        "DtGlobeCityRum",
        "DtEnvironmentDiff",
        "DtAuditLogs",
        "DtDDUUsage",
        "DtEntityExport",
        "DtUSQLExport",
        "DtLicenseBreakdown",
        "DtManagementZones",
        "DtNetworkZones",
        "DtOneAgentVersions",
        "DtTagMapper",
        "DtTagCompliance",
        // "DtRumValidator"
    ], load: () => import('src/app/pages/api-tools/wrapper/wrapper.component') },
];
